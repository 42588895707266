import { useEffect, useState } from "react";
import Header from "../components/Header";
// import whatsapp from "../assets/icons/whatsapp.svg";

const ErrorMsg = ({ errortext }) => {

  return (
    <>
      <div className="text-xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
        {errortext}
      </div>
    </>
  );
};

export default ErrorMsg;
