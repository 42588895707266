import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Stepper, Step } from "react-form-stepper";
import { toast } from "react-toastify";
import { formFields } from "../constants/formFields";
import Header from "../components/Header";
import PersonalDetails from "../components/PersonalDetails";
import OtherDetails from "../components/OtherDetails";
import EshramSuccessPage from "../components/SuccessPage";
import Loader from "../components/Loader";
import step_active from "../assets/icons/step_active.svg";
import step_completed from "../assets/icons/step_completed.svg";
import step_inactive from "../assets/icons/step_inactive.svg";
import {
  loanFormCheckStatusApiCallHandler,
  sendLoanRequest,
  sendEshramRequest
} from "../service/loanService";
import "../style.css";
import { useEffect } from "react";
import DocUpload from '../components/DocUpload';
import ErrorMsg from "../components/ErrorMsg";

export default function EshramPage() {
  const [isLoading, setLoader] = useState(false);
  const [isMounted, setMounted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [loanData, setLoanData] = useState(null);
  const [eshramFormData, setEshramFormData] = useState(null);
  const [applicationId, setApplicationId] = useState("");
  const [requestExist, setRequestExist] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [eshram_monday_id, setEshram_monday_id] = useState("");
  const [ayushman_monday_id, setAyushman_monday_id] = useState("");
  const [verifyPhoneData, setVerifyPhoneData] = useState(null);
  const search = useLocation().search;
  const company = new URLSearchParams(search).get("partner") || "";
  const emp_id = new URLSearchParams(search).get("emp_id") || "";
  let fieldsState = {};
  if (formFields && company) {
    formFields[company]?.forEach((field) => (fieldsState[field.name] = ""));
  }

  useEffect(() => {
    const _data = JSON.parse(localStorage.getItem(`${company}-${emp_id}`));
    if (_data?.loanData) {
      setLoanData(_data?.loanData);
    }

    setMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      localStorage.setItem(
        `${company}-${emp_id}`,
        JSON.stringify({ loanData: loanData })
      );
    }
  }, [activeStep, loanData, company, emp_id]);

  const redirectToOtherDetailPage = (_personalDetails) => {
    setEshramFormData({
      ...eshramFormData,
      ..._personalDetails,
    });
    setActiveStep(1);
  };

  const redirectToDocUploadPage = async (_personalDetails) => {
    try {
      setLoader(true)
      setEshramFormData({
        ...eshramFormData,
        ..._personalDetails,
      });
      let data = {
        ...eshramFormData,
        ..._personalDetails,
        "form-type": "eshram",
        "event-type": "save-request"
      }
      const result = await sendEshramRequest(data);
      console.log("result", result)
      if (result.body.request_exist) {
        setRequestExist(true);
        setErrorMsg("You have already raised a request. It is in Pending status at our end. Please wait, till that request gets processed")

      }
      else {
        const { status, id, eshram_monday_id, ayushman_monday_id } = result.body;
        if (status == "success") {
          setApplicationId(id);
          setAyushman_monday_id(ayushman_monday_id);
          setEshram_monday_id(eshram_monday_id);
          setActiveStep(2);
        }
      }
    } catch (error) {
      const errorMessage = error?.message || error?.data?.message || error?.data || error || "Unknown Error";
      console.error(errorMessage);
      toast.error(`Error Occurred. Please try again later. Details: ${errorMessage}`);
    }
    finally {
      setLoader(false)
    }
  };

  const saveEshramForm = async (_docData) => {
    try {
      setLoader(true)
      setEshramFormData({
        ...eshramFormData,
        ..._docData
      })
      let data = {
        ...eshramFormData,
        ..._docData,
        requestId: applicationId,
        ayushman_monday_id: ayushman_monday_id,
        eshram_monday_id: eshram_monday_id,
        "form-type": "eshram",
        "event-type": "save-doc"
      }
      console.log("reqeustdata", data)
      const result = await sendEshramRequest(data);
      console.log("result", result)
      const { status } = result;
      if (status === "success") {
        setActiveStep(3);
        localStorage.removeItem(`${company}-${emp_id}`);
      } else {
        window.gtag("event", "submission_error", { employee_id: emp_id });
      }
    } catch (error) {
      const errorMessage = error?.message || error?.data?.message || error?.data || error || "Unknown Error";
      console.error(errorMessage);
      toast.error(`Error Occurred. Please try again later. Details: ${errorMessage}`);
    }
    finally {
      setLoader(false)
    }
  }

  const renderLoadForm = () => {
    switch (activeStep) {
      case 0:
        return (
          <PersonalDetails
            company={"eshram"}
            formFields={formFields}
            // loanData={loanData}
            redirectToStep={redirectToOtherDetailPage}
          />
        );
      case 1:
        return (
          <OtherDetails
            company={"eshram"}
            formFields={formFields}
            redirectToStep={redirectToDocUploadPage}
          />
        );
      case 2:
        return (
          <DocUpload
            company={"eshram"}
            redirectToStep={saveEshramForm}
          />
        );
      case 3:
        return (
          <EshramSuccessPage
            company={"eshram"}
          />
        );
      default:
        break;
    }
  };
  const getStepImg = (_fromStep) => {
    if (activeStep === _fromStep) {
      return step_active;
    } else if (activeStep > _fromStep) {
      return step_completed;
    } else if (activeStep < _fromStep) {
      return step_inactive;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Header company={"nammaYatri"} />
      {!requestExist ? (
        <>
          <Stepper activeStep={activeStep} className="!p-0 loan-stepper my-3">
            <Step
              label="Step 1"
              className="custom-step"
              style={{
                backgroundImage: `URL(${getStepImg(0)})`,
                backgroundSize: "cover",
                backgroundColor: "unset",
              }}
            />
            <Step
              label="Step 2"
              className="custom-step"
              style={{
                backgroundImage: `URL(${getStepImg(1)})`,
                backgroundSize: "cover",
                backgroundColor: "unset",
              }}
            />
            <Step
              label="Step 3"
              className="custom-step"
              style={{
                backgroundImage: `URL(${getStepImg(2)})`,
                backgroundSize: "cover",
                backgroundColor: "unset",
              }}
            />
          </Stepper>
          {renderLoadForm()}
        </>
      )
        : (<>
          <ErrorMsg errortext={errorMsg}></ErrorMsg>
        </>)}
    </>
  );
}
